var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex"},[(_vm.isSuperAdmin || _vm.permissionsUser.ManageTeam === _vm.roleName.action)?_c('v-btn',{staticClass:"mb-2",attrs:{"small":"","color":"success"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Add new team ")]):_vm._e(),(_vm.isSuperAdmin || _vm.permissionsUser.ManageTeam === _vm.roleName.action)?_c('v-divider',{staticClass:"ms-2 mb-1",attrs:{"vertical":""}}):_vm._e(),_c('v-btn',{staticClass:"mx-2 mb-2",attrs:{"color":"grey white--text","small":"","to":{
                name: 'staff'
            },"disabled":_vm.loading}},[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v(" mdi-arrow-left-circle ")]),_vm._v(" Back ")],1)],1),_c('delete-dialog',{ref:"deleteDialog",attrs:{"success-message":"Data is deleted"},on:{"on-delete":function($event){return _vm.fetchAll();}}},[_vm._v(" Are you sure you want to "),_c('span',{staticClass:"red--text"},[_vm._v("delete")]),_vm._v(" this Team? ")]),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"d-flex justify-end py-1 background-title-card "},[_c('v-card-title',{staticClass:"text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"},[_vm._v(" "+_vm._s(_vm.teamId ? 'Edit team' : 'Add new team')+" ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false; _vm.teamId = null}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-close-circle-outline ")])],1)],1),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-text',{staticClass:"pb-0 pt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","align-self":"center"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Name","hide-details":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"6","align-self":"center"}},[_c('v-autocomplete',{staticClass:"mb-1",attrs:{"items":_vm.specialties,"item-text":"name","item-value":"id","dense":"","hide-details":"","outlined":"","label":"Specialties","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"me-1"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.specialtyIds.length - 1)+") ")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleSpecialty}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"indigo darken-4"}},[_vm._v(_vm._s(_vm.iconSpecialty))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All")])],1)],1),_c('v-divider')]},proxy:true}]),model:{value:(_vm.specialtyIds),callback:function ($$v) {_vm.specialtyIds=$$v},expression:"specialtyIds"}})],1),(0)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","align-self":"center"}},[_c('v-autocomplete',{staticClass:"mb-1",attrs:{"label":"Areas","clearable":"","items":_vm.sectorsMine,"item-value":"id","item-text":"name","return-object":false,"loading":_vm.sectorsLoading,"disabled":_vm.sectorsLoading,"outlined":"","dense":"","hide-details":"auto"},on:{"click:clear":function($event){_vm.cityId = []; _vm.regionIds = []},"change":function($event){return _vm.getCities()}},model:{value:(_vm.sectorId),callback:function ($$v) {_vm.sectorId=$$v},expression:"sectorId"}})],1):_vm._e(),(0)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","align-self":"center"}},[_c('v-autocomplete',{staticClass:"mb-1",attrs:{"label":"City","items":_vm.sectorId === null ? _vm.cities : _vm.citiesBySectorFilter,"item-value":"id","item-text":"name","clearable":"","outlined":"","dense":"","hide-details":"auto","multiple":""},on:{"click:clear":function($event){_vm.regionIds = []}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"me-1"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.cityId.length - 1)+") ")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleCitiesDialog}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"indigo darken-4"}},[_vm._v(_vm._s(_vm.iconCitiesDialog))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All")])],1)],1),_c('v-divider')]},proxy:true}],null,false,2562774041),model:{value:(_vm.cityId),callback:function ($$v) {_vm.cityId=$$v},expression:"cityId"}})],1):_vm._e(),(0)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6","align-self":"center"}},[_c('v-autocomplete',{staticClass:"mb-1",attrs:{"label":"Regions","clearable":"","items":_vm.cityId ? _vm.regionsAll.filter(function (c) { return _vm.cityId.includes(c.cityId); }) : _vm.regionsAll,"item-value":"id","item-text":"name","loading":_vm.sectorsLoading,"disabled":_vm.sectorsLoading,"outlined":"","dense":"","hide-details":"auto","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"me-1"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.regionIds.length - 1)+") ")]):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleRegion}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"indigo darken-4"}},[_vm._v(_vm._s(_vm.iconRegions))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All")])],1)],1),_c('v-divider')]},proxy:true}],null,false,655907430),model:{value:(_vm.regionIds),callback:function ($$v) {_vm.regionIds=$$v},expression:"regionIds"}})],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"success","small":""}},[_vm._v("Save")]),_c('v-btn',{attrs:{"small":"","text":"","color":"alert-color--text"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")])],1)],1)],1)],1),_c('bee-handy-table',{attrs:{"items":_vm.teams,"headers":_vm.headers,"dense":"","zebra":"","loading":_vm.loading,"hide-default-footer":"","height":_vm.$vuetify.breakpoint.height - 100,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"color":"edit-color","icon":""},on:{"click":function($event){_vm.teamId = item.id ;_vm.dialog = true}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"color":"alert-color","icon":""},on:{"click":function($event){_vm.teamId = item.id; _vm.$refs.deleteDialog.activeDeleteDialog(item.id, 'teams')}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }