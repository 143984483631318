export const logo = require('@/assets/brands/OUBARI.png');
export const drawerLogos = {
    normal: require('@/assets/brands/Untitled-1.png'),
    mini: require('@/assets/brands/Untitled-1.png')
};

export const colors = {
    primary:  '#041943',
    // '#0b5b6f', // #253c99  #014a97  #  041943
    primaryLight: '#ccddfd',
    //  '#a0ecff', // #b2c1ff #c0dfff #  
    secondary: '#5371ad', // #f57f20 // # f57f20
    lightBlue: '#85b901', // #0996b9 #103aa2 # 0996b9
}