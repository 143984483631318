<template>
    <div>
        <!-- roleDialog -->
        <v-dialog v-model="roleDialog" width="400">
            <v-card>
                <div class="d-flex justify-end py-1 background-title-card ">
                    <v-card-title
                        class="text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"
                    >
                        Add New Role
                    </v-card-title>
                    <!-- close button -->
                    <v-btn icon @click="roleDialog = false; $refs.form.resetValidation()">
                        <v-icon color="white">
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </div>

                <!-- text -->
                <v-card-text class="pt-2 pb-0">
                    <v-row>
                        <v-col cols="6" align-self="center">
                            <v-text-field
                                v-model="dialogData.name"
                            	label="Name"
                            	dense
                            	outlined
                            	hide-details="auto"
                                :disabled="submitLoading"
                            />
                        </v-col>
                        <v-col cols="6" align-self="center">
                            <v-autocomplete
                                v-model="dialogData.parentId"
                                :items="roles"
                                item-text="name"
                                item-value="id"
                                dense
                                outlined
                                label="Direct manager"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                    	@click="save()"
                    	text
                    	color="success"
                        :loading="submitLoading"
                    >
                        Save
                    </v-btn>

                    <v-btn
                    	@click="roleDialog = false"
                    	text
                        :disabled="submitLoading"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-row class="mt-3 mx-1">
            <v-col
                cols="12"
                sm="12"
                md="3"
                class="style-tree px-0 py-0"
            >
                <!-- add button -->
                <div class="px-2 pt-0 pb-md-0 pb-1 mt-2">
                    <v-btn
                        v-if="isSuperAdmin || permissionsUser.Roles === roleName.action"
                        elevation="1"
                        color="primary"
                        class="px-2 mb-2"
                        :disabled="disableAddBtn"
						small
                        @click="roleDialog = true"
                    >
                        <v-icon class="me-1">mdi-seed-plus-outline</v-icon>
                        add
                    </v-btn>
                </div>
                <!-- tree -->
                <bee-treeview
					:items="roles"
					:itemOpen.sync="itemOpen"
					:active.sync="activeNodes"
					:open.sync="openNodes"
					hide-expand-icon
					active-class="deep-orange--text text--darken-1"
					@update:active="previewItem(activeNodes[0]); openNodes.push($event.id)"
					:disabled="disableAddBtn"
                    activatable
                    :calculate-width="true"
				>
					<template v-slot:prepend="{ item, open, leaf }">
						<v-icon
							v-if="!leaf"
							class="me-0"
							@click="
								open && !disableAddBtn
								? openNodes.splice(openNodes.indexOf(item.id), 1)
								: openNodes.push(item.id)"
						>
							{{open ? 'mdi-minus' : 'mdi-plus'}}
						</v-icon>
					</template>
				</bee-treeview>
            </v-col>
            <v-col cols="12" sm="12" md="9">
                <template v-if="$route.params.id">
                    <v-row>
                        <v-col align-self="center" cols="4">
                            <v-text-field
                                v-model="namingRole"
                                label="Job title"
                                dense
                                outlined
                                hide-details
                                :disabled="Boolean($route.query.preview) || loading"
                                :loading="loading"
                            />
                        </v-col>
                        <v-col align-self="center" cols="4">
                            <v-autocomplete
                                :items="roles.filter(c => c.name !== namingRole)"
                                item-text="name"
                                item-value="id"
                                v-model="parentId"
                                label="Manager"
                                dense
                                outlined
                                hide-details
                                :disabled="Boolean($route.query.preview) || loading"
                                :loading="loading"
                            />
                        </v-col>
                        <v-spacer />
                        <v-col class="d-flex justify-end">
                            <v-btn
                                v-if="isSuperAdmin || permissionsUser.Roles === roleName.action"
                                @click="$route.query.preview ? $router.replace({ 
                                    name: 'Roles', 
                                    params: { id: $route.params.id },
                                    query: {} 
                                }) : update()"
                                small
                                :color="$route.query.preview ? 'edit-color white--text' : 'success' "
                                :disabled="!Boolean($route.params.id)"
                            >
                                <v-icon small class="me-2"> 
                                    {{ $route.query.preview ? 'mdi-pencil' : 'mdi-content-save' }}
                                </v-icon>
                                {{ $route.query.preview ? 'Edit' : 'Save' }}  
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div>
                        <h3 class="primary--text mb-2"> permissions </h3>
                        <bee-handy-table
                            :headers="headers"
                            :items="myItems"
                            dense
                            zebra
                            :height="$vuetify.breakpoint.height - 160"
                            :items-per-page="-1"
                            fixed-header
                            hide-default-footer
                            :loading="loading"
                        >
                            <template v-slot:item.rolePermissions="{ item }">
                                <v-radio-group
                                    v-model="item.rolePermissions"
                                    row
                                    hide-details
                                    class="mt-0"
                                    :disabled="Boolean($route.query.preview)"
                                >
                                    <v-radio
                                        label="Read"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Action"
                                        :value="2"
                                    ></v-radio>
                                    <v-radio
                                        label="Nothing"
                                        :value="0"
                                    ></v-radio>
                                </v-radio-group>
                            </template>
                        </bee-handy-table>
                    </div>
                </template>
                <div
                	v-else
                	class="d-flex flex-column justify-center align-center"
                	:style="{ height: `${$vuetify.breakpoint.height - 90}px` }"
                >
                    <v-progress-circular
                    	v-if="loading"
                        color="primary"
                    	indeterminate
                        size="44"
                        width="4"
                    />
                    <template v-else>
                        <v-icon size="60" class="mb-1">mdi-account-cog</v-icon>
                        <span class="text-h6">Please select a role</span>
                    </template>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { permissions, permissionsWithName, roleName } from '../../../helpers/enums'
export default {
    data () {
        return {
            permissions,
            permissionsWithName,
  
            submitLoading: false,
            loading: false,
            roleName,
        
            namingRole: null,
            parentId: null,
            dialogData: {
                name: null,
                parentId: null,
            },
            roleDialog: false,
            itemOpen: null,
			activeNodes: [],
			openNodes: [],
            disableAddBtn: false,
            myItems: [
                {
                    permissionsNames: permissions.Leaves,
                    rolePermissions: null,
                    explain: 'قبول/رفض الاجازات المقدمة من الموظفين'
                },
                {
                    permissionsNames: permissions.AddLeavesForStaff,
                    rolePermissions: null,
                    explain: 'إضافة إجازة'
                },
                {
                    permissionsNames: permissions.ManageLeaveBalances,
                    rolePermissions: null,
                    explain: 'إدارة عدد أيام الإجازات السنوية'
                },
                {
                    permissionsNames: permissions.GenerateMaterials,
                    rolePermissions: null,
                    explain: 'إدارة و توليد المنتجات'
                },
                {
                    permissionsNames: permissions.MaterialTransactions,
                    rolePermissions: null,
                    explain: 'مناقلة المواد بين الموظفين.',
                },
                {
                    permissionsNames: permissions.MedicalPlans,
                    rolePermissions: null,
                    explain: 'إدارة واستعراض خطط المندوبين.',
                },
                {
                    permissionsNames: permissions.MedicalVisits,
                    rolePermissions: null,
                    explain: 'إدارة واستعراض زيارات المندوبين',
                },
                {
                    permissionsNames: permissions.WeeklyReports,
                    rolePermissions: null,
                    explain: 'إدارة واستعراض تقارير الزيارة المزدوجة- التقرير الإداري',
                },
                {
                    permissionsNames: permissions.MonthlyPlanReports,
                    rolePermissions: null,
                    explain: 'التقارير الشهرية',
                },
                {
                    permissionsNames: permissions.Reviews,
                    rolePermissions: null,
                    explain: 'إدارة واستعراض تقييمات.',
                },
                {
                    permissionsNames: permissions.Exams,
                    rolePermissions: null,
                    explain: 'إدارة واستعراض اختبارات. ',
                },
                {
                    permissionsNames: permissions.Salaries,
                    rolePermissions: null,
                    explain: 'إدارة واستعراض الرواتب. ',
                },
                {
                    permissionsNames: permissions.MedicalReps,
                    rolePermissions: null,
                    explain: 'إدارة واستعراض بيانات الموظفين.',
                },
                {
                    permissionsNames: permissions.Entities,
                    rolePermissions: null,
                    explain: ' إدارة واستعراض بيانات الزبائن.',
                },
                {
                    permissionsNames: permissions.EntityModificationRequests,
                    rolePermissions: null,
                    explain: 'الموافقة او الاطلاع على طلبات التعديل للزبائن المرسلة من قبل المندوبين.',
                },
                {
                    permissionsNames: permissions.Charts,
                    rolePermissions: null,
                    explain: 'الوصول للمخططات وإنشائها.',
                },
                {
                    permissionsNames: permissions.Regions,
                    rolePermissions: null,
                    explain: 'إدارة المدن والمناطق.',
                },
                {
                    permissionsNames: permissions.Users,
                    rolePermissions: null,
                    explain: 'تعديل بيانات الدخول للموظفين كـ الـUserName و الPassword يجب إضافة القيمة read للـMedicalRep للاطلاع على الملف الشخصي.',
                },
                {
                    permissionsNames: permissions.Roles,
                    rolePermissions: null,
                    explain: 'إدارة الأدوار الوظيفية وصلاحياتها.',
                },
                {
                    permissionsNames: permissions.GeneralFilter,
                    rolePermissions: null,
                    explain: 'الفلاتر العامة ضمن النظام كـ فلتر السنوات.',
                },
                {
                    permissionsNames: permissions.ManageTeam,
                    rolePermissions: null,
                    explain: 'التعديل بـ بيانات الفرق كـ إضافة وتعديل.',
                }
            ],
            tree: null,
            jobTitle: 'team lader',

            items: [
                {
                    id: 1,
                    name: 'regional manager',
                    children: [
                        { 
                            id: 2, 
                            name: 'supervisor',
                            children: [
                                {
                                    id: 4, 
                                    name: 'Team Leader',
                                    children:[
                                        {
                                            id: 5, 
                                            name: 'Medical rep'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            radioGroup: 1
        }
    },

    watch: {
        watchRoute(val) {
			const routeDataArray = val.split(' | ');
			const routData = { name: routeDataArray[0], id: routeDataArray[1], preview: routeDataArray[2] }
			if (routData.name === 'Roles' || !routData.id) {
				this.activeNodes = [];
			}
		},

        roleDialog(val) {
            if (!val) {
                this.dialogData.name = null;
                this.dialogData.parentId = null
            }
        }
    },

    computed: {
        ...mapState({
            roles: state => state.rolePermission.roles,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            userLogin: state => state.auth.userData.employeeId,
        }),

        headers() {
            return [
                {
                    text: 'Name',
                    name: 'Name',
                    value: 'permissionsNames',
                    noNotAvailableText: true,
                    setValueExpr: val => this.permissionsWithName.find(c => c.id === val)?.permissionName
                },
                {
                    text: 'Permissions',
                    name: 'rolePermissions',
                    value: 'rolePermissions'
                },
                {
                    text: 'Explanation',
                    name: 'explain',
                    value: 'explain',
                    width: '40%'
                }
            ]
        }
    },
    // for (let i = 0; i < roles.length; i++) {
    // if (roles.find(c => c.id === roles[i].parentRoleId)) {
    // console.log(roles.find(c => c.id === roles[i].parentRoleId))
    // roles[i].children.push(
    // roles.find(c => c.id === roles[i].parentRoleId)
    // )
    // }
    // }
    // return roles

    methods: {
		previewItem(id) {
            if (!id || id === this.$route.params.id) return;
            this.loading = true

            this.$store.dispatch('rolePermission/fetchById', { id: id }).then((data) => {
                this.namingRole = data.data.name
                this.parentId = data.data.parentRoleId
                this.myItems = this.myItems.map(c => ({
                    explain: c.explain,
                    permissionsNames: c.permissionsNames,
                    rolePermissions: data.data.permissions.find(x => x.permissionsNames === c.permissionsNames)?.rolePermissions
                }))
                this.$router.replace({
                    params: {
                        id: data.data.id
                    },
                    query: {
                        preview: true
                    }
                })
            }).finally(() => {
                this.loading = false
            })
		},

        updateTree() {
			if (this.$route.name === 'Roles' && this.$route.params.id) {
				this.itemOpen = this.$route.params.id;
				this.activeNodes = [this.$route.params.id];
			}
		},

        fetchAll () {
            return this.permissionsUser.Roles !== roleName.nothing ? this.$store.dispatch('rolePermission/fetchChildrenRole', { id: this.userLogin }) : null
        },
        // add new role
        save () {
            this.submitLoading = true
            this.$store.dispatch('rolePermission/create', { 
                name: this.dialogData.name, 
                parentId: this.dialogData.parentId 
            }).then(() => {
                this.fetchAll().then((data) => {
                    this.items.push({
                        ...data
                    })
                    this.roleDialog = false
                    this.submitLoading = false
                })
            })
        },

        update () {
            this.loading = true
            this.$store.dispatch('rolePermission/update', { 
                id: this.$route.params.id,
                newName: this.namingRole,
                parentRoleId: this.parentId,
                permissions: this.myItems
            }).then((data) => {
                this.$router.replace({
                    query: {
                        preview: true
                    }
                })
            }).finally(() => {
                this.loading = false
            })
        },

		makeTree(list) {
			const findChildren = (id) => {
    			return list
      			.filter((e) => e.parentId === id)
				.map((e) => ({
        			...e,
        			children: findChildren(e.id),
      			}));
  			};

			const arr = list
			.filter((e) => !e.parentId)	
    		.map((e) => ({
      			...e,
      			children: findChildren(e.id),
		    }));
			return arr;
		}
    },

    created () {
        Promise.all([
            this.$store.dispatch('rolePermission/fetchChildrenRole', { id: this.userLogin }).then((data) => {
                this.items.push({
                    id: data.id,
                    name: data.name,
                })
            }).then(() => {
                if (this.$route.params.id) {
                    this.activeNodes.push(
                        this.$route.params.id
                    ) 
                } else {
                    this.activeNodes = []
                }
            })
        ])
    }
}
</script>

<style>
.style-tree {
    background: #118aa825;
    border-radius: 10px;
}
</style>